import "./income.scss";
import { useState, useContext, useEffect, useRef } from "react";
import { db } from "../../firebase";
import { doc, deleteDoc, collection, addDoc, setDoc } from "firebase/firestore";
import moment from "moment";
import { Timestamp } from "firebase/firestore";
import TransactionContext from "../../context/transactionContext";
import Form from "../../components/datatable/Form";
import EditAddWidget from "../../components/widget/EditAddWidget";
import SubmitButton from "../../components/datatable/SubmitButton";
import {
  AddOutlined,
  CloudDownloadOutlined,
  ExpandMoreOutlined,
} from "@mui/icons-material";
import MobileContext from "../../context/MobileContext";
import UserSettingsContext from "../../context/UserSettingsContext";
import { AuthContext } from "../../context/AuthContext";
import CategoryIcon from "../../components/icons/CategoryIcon";
import CategoryTotals from "../project/CategoryTotals";
import { lazy } from "react";
import { Suspense } from "react";
import LazyLoading from "../../components/lazyloading/LazyLoading";
import { useSelector } from "react-redux";
import PeriodSelection from "../../components/periodselection/PeriodSelection";
import { AnimatePresence, motion } from "framer-motion";
import useMeasure from "react-use-measure";
import {
  exportTransactionsToCSV,
  filterYearTransactions,
  groupTransactions,
  pSBC,
  shadeColor,
} from "../../utils/utils";
import { CircularProgress } from "@mui/material";
import { useMemo } from "react";
import EmojiIcon from "../../components/icons/EmojiIcon";

const DataGrid = lazy(() =>
  import("@mui/x-data-grid").then((module) => ({ default: module.DataGrid }))
);
const DatatableMobile = lazy(() =>
  import("../../components/datatable/DatatableMobile")
);

const Income = () => {
  let [ref, { height }] = useMeasure();
  const initialState = {
    accountId: "",
    category: "",
    vendor: "",
    description: "",
    amount: "",
  };
  const [dataInput, setDataInput] = useState(initialState);
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const { incomeData, createTransaction, deleteTransaction } =
    useContext(TransactionContext);
  const isDarkMode = useSelector((state) => state.userSettings.isDarkMode);
  const { isMobile } = useContext(MobileContext);
  const { months } = useContext(UserSettingsContext);
  const userIncomeCategories = useSelector(
    (state) => state.userSettings.userIncomeCategories
  );
  const accounts = useSelector((state) => state.accounts.userAccounts);
  const currency = useSelector((state) => state.userSettings.currency);
  const optionsContainerRef = useRef();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [isOnEditAdd, setIsOnEditAdd] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState({
    showMessage: false,
    id: "",
  });
  const [isEditing, setIsEditing] = useState(false);
  const [editingId, setEditingId] = useState("");
  const [showOptions, setShowOptions] = useState(false);
  const [totalSpent, setTotalSpent] = useState({
    wholeNumber: "0",
    cent: "",
  });
  const [categoryAmountTotals, setCategoryAmountTotals] = useState({});
  const [showCategory, setShowCategory] = useState(false);
  const selectedYear = useSelector((state) => state.userSettings.options.year);
  const selectedMonth = useSelector(
    (state) => state.userSettings.options.month
  );
  const selectedPeriodicDisplay = useSelector(
    (state) => state.userSettings.options.periodicDisplay
  );

  const handleInput = (e) => {
    const id = e.target.id;
    let value = e.target.value;
    if (id === "amount") {
      const num = value.replace(/[^0-9$.,]/g, "").replace(/,/gi, "");
      const parts = num.split("."); //split whole number and decimal
      parts[0] = parts[0].split(/(?=(?:\d{3})+$)/).join(","); // split whole number with commas
      value = parts.join(".");
      // value = num.split(/(?=(?:\d{3})+$)/).join(",")
    }

    setDataInput({ ...dataInput, [id]: value });
  };

  const onChangeDate = (e) => {
    const newDate = moment(e.target.value).format("YYYY-MM-DD");
    setDate(newDate);
  };

  const handleDelete = async (id) => {
    await deleteTransaction(id);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    let amountValue = parseFloat(dataInput.amount.replace(/,/gi, ""));
    let mode = "create";
    // edit existing document
    if (isEditing) {
      mode = "update";
    }

    await createTransaction(
      mode,
      dataInput,
      date,
      "income",
      amountValue,
      undefined, //currency for future
      isEditing ? editingId : undefined,
      undefined
    );

    setDataInput(initialState);
    setDate(moment().format("YYYY-MM-DD"));
    setIsSubmitting(false);
    setIsOnEditAdd(false);
    setIsEditing(false);
    setEditingId("");
  };

  const handleEdit = (id) => {
    setIsOnEditAdd(true);
    setIsEditing(true);
    setEditingId(id);
    const editData = incomeData.find((item) => item.id === id);
    const myDate = moment(new Date(editData.date.seconds * 1000)).format(
      "YYYY-MM-DD"
    );
    setDate(myDate);

    setDataInput({
      accountId:
        accounts.find((item) => item.id === editData.accountId)?.id || "",
      category: editData.category,
      vendor: editData.vendor,
      description: editData.description,
      amount: editData.amount.toLocaleString("en-US"),
    });
  };

  const handleClose = () => {
    setIsOnEditAdd(false);
    setIsEditing(false);
    setEditingId("");
    setDataInput(initialState);
    setDate(moment().format("YYYY-MM-DD"));
  };

  const handleExportCSV = () => {
    setIsExporting(true);
    exportTransactionsToCSV("income", incomeData, accounts);
    setIsExporting(false);
  };

  const dataColumns = [
    {
      field: "date",
      headerName: "Date",
      flex: 1,
      minWidth: 105,
      align: "center",
      headerAlign: "center",
      type: "date",
      valueGetter: (params) => {
        const { seconds } = params.value;
        const date = new Date(seconds * 1000);
        return date;
      },
      valueFormatter: (params) => {
        return params.value?.toLocaleDateString();
      },
    },
    {
      field: "accoundId",
      headerName: "Account",
      type: "singleSelect",
      valueOptions: accounts.map((item) => item.name),
      flex: 2,
      minWidth: 130,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        const account = accounts.find(
          (item) => item.id === params.row.accountId
        );
        return (
          <div
            className={"cellWithColor"}
            style={{
              color: account?.color
                ? shadeColor(account?.color, isDarkMode ? 20 : -20)
                : "",
              backgroundColor: account?.color + "1A",
              border: `1px solid ${account?.color}33`,
            }}
          >
            {account && account.name}
          </div>
        );
      },
      valueGetter: (params) => {
        const accountName =
          accounts.find((item) => item.id === params.row.accountId)?.name || "";
        return accountName;
      },
    },
    {
      field: "category",
      headerName: "Category",
      type: "singleSelect",
      valueOptions: userIncomeCategories.map((item) => item.label),
      flex: 2,
      minWidth: 130,
      align: "left",
      headerAlign: "center",
      renderCell: (params) => {
        const category = userIncomeCategories.find(
          (item) => item.label === params.row.category
        );
        return (
          <div className={`cellWithIcon ${params.row.category}`}>
            {category && <EmojiIcon emoji={category.icon} />}
            <div className="categoryLabel">{params.row.category}</div>
          </div>
        );
      },
    },
    {
      field: "vendor",
      headerName: "Source",
      flex: 2,
      minWidth: 130,
      align: "left",
      headerAlign: "center",
    },
    {
      field: "description",
      headerName: "Description",
      flex: 4,
      minWidth: 130,
      headerAlign: "center",
    },

    {
      field: "amount",
      headerName: "Amount",
      type: "number",
      flex: 2,
      minWidth: 100,
      headerAlign: "center",
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        const valueFormatted = Number(params.value).toLocaleString("en-US");
        return `${currency.symbol} ${valueFormatted}`;
      },
    },
    // {
    //   field: "type",
    //   headerName: "Type",
    //   flex: 1,
    //   minWidth: 100,
    //   align: 'center',
    //   headerAlign: 'center',
    //   renderCell: (params) => {
    //     return (
    //       <div className={`cellWithType ${params.row.type}`}>
    //         {params.row.type}
    //       </div>
    //     );
    //   },
    // },
  ];

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      flex: 2,
      minWidth: 110,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <div
              className="editButton"
              onClick={() => {
                handleEdit(params.row.id);
              }}
            >
              Edit
            </div>
            <div
              className="deleteButton"
              onClick={() => {
                setConfirmDelete({ showMessage: true, id: params.row.id });
              }}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    document.title = "Expense Aware: Income";
  }, []);

  useEffect(() => {
    const handler = (e) => {
      if (!optionsContainerRef.current.contains(e.target)) {
        setShowOptions(false);
      }
    };

    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, [optionsContainerRef]);

  const groupedIncome = useMemo(() => {
    const filterDateBegYear = new Date(selectedYear, 0, 1);
    const filterDateEndYear = new Date(selectedYear, 11, 31);

    const incomeTransactionsDateFiltered = filterYearTransactions(
      incomeData,
      filterDateBegYear,
      filterDateEndYear
    );

    const groupedIncome = groupTransactions(
      incomeTransactionsDateFiltered,
      months
    );

    return groupedIncome;
  }, [selectedYear, incomeData]);
  useEffect(() => {
    const totalAmount =
      selectedPeriodicDisplay === "monthly"
        ? groupedIncome[selectedMonth]?.totalMonth
        : groupedIncome.yearTotal;
    const [wholeNumber, cent] = (totalAmount || 0)
      ?.toLocaleString()
      ?.split(".");
    let categoryTotals = {};
    if (selectedPeriodicDisplay === "monthly") {
      if (groupedIncome[selectedMonth]?.category) {
        Object.keys(groupedIncome[selectedMonth]?.category).forEach((key) => {
          categoryTotals[key] =
            groupedIncome[selectedMonth]?.category[key].categoryTotal;
        });
      }
    } else {
      categoryTotals = groupedIncome.categoryTotal;
    }
    setTotalSpent({ wholeNumber: wholeNumber, cent: cent });
    setCategoryAmountTotals(categoryTotals);
  }, [incomeData, selectedMonth, selectedYear, selectedPeriodicDisplay]);

  return (
    <div className="income">
      <h1 className="pageTitle">Income</h1>
      <div className="gridContainer">
        <div className="leftContainer">
          {/* {!isOnEditAdd && (
            <div className="topContent">
              <div className="datatableInputs">
                <Form
                  handleSubmit={handleSubmit}
                  date={date}
                  onChangeDate={onChangeDate}
                  handleInput={handleInput}
                  categories={userIncomeCategories}
                  dataInput={dataInput}
                  type={"income"}
                />
                <SubmitButton
                  isSubmitting={isSubmitting}
                  isEditing={isEditing}
                  type={"income"}
                />
              </div>
            </div>
          )} */}
          <div className="bottomContent">
            <div className="datatableTitle">
              Income Records
              <div className="topButtons">
                <button
                  onClick={handleExportCSV}
                  className="exportButton"
                  disabled={isExporting}
                >
                  {isExporting ? (
                    <CircularProgress size={"1.275rem"} className="spinner" />
                  ) : (
                    <CloudDownloadOutlined className="icon" />
                  )}
                  CSV
                </button>
                <button
                  className="buttonContainer"
                  onClick={() => setIsOnEditAdd(true)}
                >
                  {isSubmitting ? (
                    <CircularProgress size={"1.275rem"} className="spinner" />
                  ) : (
                    <>
                      {!isEditing && <AddOutlined className="icon" />}
                      <span>Add New</span>
                    </>
                  )}
                </button>
              </div>
            </div>
            {/* desktop version */}
            {!isMobile && (
              <div className="datagridContainer">
                <Suspense fallback={<LazyLoading />}>
                  <DataGrid
                    className="datagrid"
                    rows={incomeData}
                    columns={dataColumns.concat(actionColumn)}
                    pageSize={20}
                    rowsPerPageOptions={[20]}
                    sx={{
                      "& .MuiDataGrid-row:hover": {
                        backgroundColor: isDarkMode ? "#2d2d2d" : "#e1e5ee",
                      },
                    }}
                  />
                </Suspense>
              </div>
            )}

            {/* mobile version */}
            {isMobile && (
              <Suspense fallback={<LazyLoading />}>
                <DatatableMobile
                  data={incomeData}
                  handleEdit={handleEdit}
                  userCategories={userIncomeCategories}
                  currency={currency}
                />
              </Suspense>
            )}
          </div>
          {/* Edit Add Form */}
          <AnimatePresence>
            {isOnEditAdd && (
              <EditAddWidget
                handleSubmit={handleSubmit}
                date={date}
                onChangeDate={onChangeDate}
                handleInput={handleInput}
                categories={userIncomeCategories}
                dataInput={dataInput}
                setIsOnEditAdd={setIsOnEditAdd}
                handleClose={handleClose}
                isSubmitting={isSubmitting}
                isEditing={isEditing}
                editingId={editingId}
                type={"income"}
                setConfirmDelete={setConfirmDelete}
              />
            )}
          </AnimatePresence>
          <div className="add-toggle" onClick={() => setIsOnEditAdd(true)}>
            <AddOutlined className="icon" />
          </div>
          <AnimatePresence>
            {confirmDelete.showMessage && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{
                  opacity: 1,
                  transition: { duration: 0.4, ease: [0.36, 0.66, 0.04, 1] },
                }}
                exit={{
                  opacity: 0,
                  transition: { duration: 0.3, ease: [0.36, 0.66, 0.04, 1] },
                }}
                className="confirmDelete"
              >
                <motion.div
                  initial={{ scale: 0.8, opacity: 0 }}
                  animate={{
                    scale: 1,
                    opacity: 1,
                    transition: {
                      delay: 0.1,
                      duration: 0.4,
                      type: "spring",
                      ease: [0.36, 0.66, 0.04, 1],
                    },
                  }}
                  exit={{
                    scale: 0.8,
                    opacity: 0,
                    transition: {
                      duration: 0.3,
                      ease: [0.36, 0.66, 0.04, 1],
                    },
                  }}
                  className="confirmDeleteContainer"
                >
                  <h1>Are you sure you want to delete record?</h1>
                  <div className="row">
                    <button
                      className="btn-cancel"
                      onClick={() =>
                        setConfirmDelete({ showMessage: false, id: "" })
                      }
                    >
                      Cancel
                    </button>
                    <button
                      className="deleteButton"
                      onClick={() => {
                        handleDelete(confirmDelete.id);
                        setConfirmDelete({ showMessage: false, id: "" });
                        handleClose();
                      }}
                    >
                      Delete
                    </button>
                  </div>
                </motion.div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>

        <div className="rightContainer">
          <div className="title">Overview</div>
          <div className="totals">
            <div className="totalSpent">
              Total Income:
              <div className="period" ref={optionsContainerRef}>
                <div
                  className="currentPeriod"
                  onClick={() => setShowOptions(!showOptions)}
                >
                  <span>{`${
                    selectedPeriodicDisplay === "monthly"
                      ? selectedMonth?.substring(0, 3)
                      : ""
                  } ${selectedYear}`}</span>
                  <ExpandMoreOutlined className="icon" />
                </div>

                {showOptions && <PeriodSelection shortTitle={true} />}
              </div>
            </div>
            <div className="amountTotals">
              <span className="currency">{currency.symbol}</span>
              {totalSpent.wholeNumber}
              {totalSpent.cent && (
                <span className="cent">.{totalSpent.cent}</span>
              )}
            </div>
          </div>
          <hr className="lineBreak" />
          <div className="row">
            <div className="category">Per category</div>
            <button
              className="showCategoryToggle expandToggleBtn"
              onClick={() => setShowCategory(!showCategory)}
            >
              {showCategory ? "Hide" : "Show"}
            </button>
          </div>
          <motion.div
            animate={{
              height: height,
              transition: { duration: 0.4, ease: [0.36, 0.66, 0.04, 1] },
            }}
            className="overflowHidden"
          >
            <div ref={ref}>
              <CategoryTotals
                categoryAmountTotals={categoryAmountTotals}
                showCategory={showCategory}
                type="income"
              />
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Income;
