import { useState, useEffect, useContext } from "react";
import { createContext } from "react";
import {
  collection,
  doc,
  getDocs,
  query,
  Timestamp,
  updateDoc,
  where,
  writeBatch,
} from "firebase/firestore";
import { db } from "../firebase";
import { AuthContext } from "./AuthContext";
import { getNextBillingDate } from "../utils/utils";

const SubscriptionContext = createContext();

export const SubscriptionContextProvider = ({ children }) => {
  const { currentUser, onDemo } = useContext(AuthContext);
  const [subscriptionData, setSubscriptionData] = useState([]);
  const [expenseRecordsRecorded, setExpenseRecordsRecorded] = useState([]);

  const updateSubscriptionCategory = async (newCategory, oldCategory) => {
    if (currentUser && !onDemo) {
      try {
        let operationCounter = 0;
        let batch = writeBatch(db);
        const subscriptionQuery = query(
          collection(db, "subscription"),
          where("userId", "==", currentUser.uid),
          where("category", "==", oldCategory)
        );
        const subscriptionArray = await getDocs(subscriptionQuery);

        subscriptionArray.forEach(async (document) => {
          batch.update(document.ref, { category: newCategory });
          operationCounter += 1;

          if ((operationCounter + 1) % 499 == 0) {
            await batch.commit();
            batch = writeBatch(db);
          }
        });

        // final commit
        if (!(operationCounter % 499) == 0) {
          await batch.commit();
        }
      } catch (err) {
        console.log(err);
        return;
      }
    }

    setSubscriptionData((prevState) =>
      [...prevState].map((item) => {
        if (item.category === oldCategory) {
          return { ...item, category: newCategory };
        }
        return { ...item };
      })
    );
  };

  useEffect(() => {
    let list = [];
    let unsubscribed = false;

    // ---------------- demo mode only -------------------
    if (!currentUser && onDemo) {
      import("./dummyData.js").then((module) => {
        module.dummySubscriptions.map((subscription) => {
          const {
            lastRecordDate,
            startDate,
            billingCycle,
            category,
            cost,
            description,
            frequency,
            name,
            vendor,
            totalCostIncurred,
            accountId,
            transactionCurrency,
          } = subscription;
          let billingDate = new Date(lastRecordDate.seconds * 1000);
          const billingDay =
            billingCycle === "Start of Period"
              ? new Date(startDate.seconds * 1000).getDate()
              : new Date(startDate.seconds * 1000).getDate() - 1;
          let lastRecordDateUpdated = billingDate;
          let totalCostIncurredUpdated = totalCostIncurred;
          let expenseRecordToSubmit = [];

          while (billingDate <= new Date()) {
            billingDate = getNextBillingDate(
              billingDate,
              frequency,
              billingDay
            );

            // create record only if billingDate after frequency < now
            if (billingDate <= new Date()) {
              const expenseRecord = {
                id: Math.random().toString(36),
                type: "expense",
                ...(accountId && { accountId: accountId }),
                ...(transactionCurrency && {
                  transactionCurrency: transactionCurrency,
                }),
                userId: "test",
                category: category,
                vendor: vendor,
                description: `Recurring: ${name} - ${description}`,
                amount: cost,
                date: Timestamp.fromDate(
                  new Date(
                    billingDate.getFullYear(),
                    billingDate.getMonth(),
                    billingDate.getDate()
                  )
                ), // -1 no longer applicable
              };
              // for update of document
              lastRecordDateUpdated = billingDate;
              totalCostIncurredUpdated += cost;
              expenseRecordToSubmit.push(expenseRecord);
            } // end if: if (billingDate <= new Date())
          } // end while loop: (billingDate <= new Date())

          list.push({
            ...subscription,
            id: list.length.toString(),
            lastRecordDate: Timestamp.fromDate(lastRecordDateUpdated),
            nextBillingDate: billingDate,
            totalCostIncurred: totalCostIncurredUpdated,
          });
        });

        // expense records update are done in dummyData.js
        setSubscriptionData(list);
      });

      return;
    } // ---------------- demo mode only -------------------

    const fetchData = async () => {
      const q = query(
        collection(db, "subscription"),
        where("userId", "==", currentUser.uid)
      );
      const res = await getDocs(q);

      if (!unsubscribed) {
        res.forEach(async (document) => {
          //create expense records from last record to now
          const {
            lastRecordDate,
            startDate,
            billingCycle,
            category,
            cost,
            description,
            frequency,
            name,
            vendor,
            totalCostIncurred,
            accountId,
            transactionCurrency,
          } = document.data();
          let billingDate = new Date(
            new Date(lastRecordDate.seconds * 1000).setHours(0, 0, 0, 0)
          );
          const billingDay =
            billingCycle === "Start of Period"
              ? new Date(startDate.seconds * 1000).getDate()
              : new Date(startDate.seconds * 1000).getDate() - 1;
          let lastRecordDateUpdated = billingDate;
          let totalCostIncurredUpdated = totalCostIncurred;
          let expenseRecordToSubmit = [];

          // no difference between billingCycle since: lastRecordDate + frequency for both works
          // add frequency to the billingDate first for both
          while (billingDate <= new Date()) {
            billingDate = getNextBillingDate(
              billingDate,
              frequency,
              billingDay
            );

            // create record only if billingDate after frequency < now
            if (billingDate <= new Date()) {
              const expenseRecord = {
                type: "expense",
                userId: currentUser.uid,
                ...(accountId && { accountId: accountId }),
                ...(transactionCurrency && {
                  transactionCurrency: transactionCurrency,
                }),
                category: category,
                vendor: vendor,
                description: `Recurring: ${name} - ${description}`,
                amount: cost,
                date: Timestamp.fromDate(
                  new Date(
                    billingDate.getFullYear(),
                    billingDate.getMonth(),
                    billingDate.getDate()
                  )
                ), // -1 no longer applicable
              };
              // for update of document
              lastRecordDateUpdated = billingDate;
              totalCostIncurredUpdated += cost;
              expenseRecordToSubmit.push(expenseRecord);
            } // end if: if (billingDate <= new Date())
          } // end while loop: (billingDate <= new Date())

          // add new documents to expense:"transactions"
          const batch = writeBatch(db);

          expenseRecordToSubmit.forEach((record) => {
            const docRef = doc(collection(db, "transactions"));
            batch.set(docRef, record);
          }); //end forEach(record)

          try {
            await batch.commit();
            setExpenseRecordsRecorded(expenseRecordToSubmit);

            // update currentDocument lastRecordDate if there is a new record
            if (
              lastRecordDateUpdated.getTime() !==
              new Date(lastRecordDate.seconds * 1000).getTime()
            ) {
              try {
                await updateDoc(doc(db, "subscription", document.id), {
                  lastRecordDate: Timestamp.fromDate(lastRecordDateUpdated),
                  totalCostIncurred: totalCostIncurredUpdated,
                });
                list.push({
                  ...document.data(),
                  id: document.id,
                  lastRecordDate: Timestamp.fromDate(lastRecordDateUpdated),
                  nextBillingDate: billingDate,
                  totalCostIncurred: totalCostIncurredUpdated,
                });
              } catch (err) {} // end of try catch error
            } else {
              // no new expense record
              list.push({
                ...document.data(),
                id: document.id,
                nextBillingDate: billingDate,
              });
            } // end if else :(lastRecordDateUpdated !== new Date(lastRecordDate.seconds * 1000))
          } catch (err) {} // end of try catch
        }); //end loop: res.forEach(document)

        setSubscriptionData(list);
      }
    };
    fetchData();
    return () => {
      unsubscribed = true;
    };
  }, []);

  return (
    <SubscriptionContext.Provider
      value={{
        subscriptionData,
        setSubscriptionData,
        expenseRecordsRecorded,
        updateSubscriptionCategory,
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  );
};

export default SubscriptionContext;
